import { getCLS, getFID, getLCP } from "web-vitals";
import CookieConsent from "react-cookie-consent";
import * as React from "react";
import { render } from "react-dom";

render(
  <CookieConsent
    location="bottom"
    buttonText="Autoriser"
    declineButtonText={"Décliner"}
    style={{ background: "#252c41", 'font-size': '1rem', display: "flex", 'align-items': 'center' , 'justify-content': 'center' }}
    contentStyle={{ width: '100%', flex: '1 0 95%', margin: '10px 40px' }}
    buttonStyle={{ color: "rgb(37, 44, 65)", 'font-weight': 'bold', 'background-color': 'rgb(137, 182, 64)', fontSize: "14px", padding: '1rem', margin: '5px 5px 10px 5px' }}
    declineButtonStyle={{ color: "rgb(37, 44, 65)", opacity: 0.8, 'font-weight': 'bold', 'background-color': '#ffffff', fontSize: "14px", padding: '1rem', margin: '5px 5px 10px 5px' }}
    enableDeclineButton
    onDecline={() => {
        window.location.href = "http://google.fr";
    }}
    flipButtons
  >
    <strong>Ce site Web utilise des cookies.</strong> <br /> Les cookies nous
    permettent de personnaliser le contenu et les annonces, d'offrir des
    fonctionnalités relatives aux médias sociaux et d'analyser notre trafic.
    Vous consentez à nos cookies si vous continuez à utiliser notre
    site Web.
  </CookieConsent>,
  document.querySelector(".consent")
);

// function sendToGoogleAnalytics({ name, delta, id }) {
//   // Assumes the global `gtag()` function exists, see:
//   // https://developers.google.com/analytics/devguides/collection/gtagjs
//   // @ts-ignore
//   gtag("event", name, {
//     event_category: "Web Vitals",
//     // Google Analytics metrics must be integers, so the value is rounded.
//     // For CLS the value is first multiplied by 1000 for greater precision
//     // (note: increase the multiplier for greater precision if needed).
//     value: Math.round(name === "CLS" ? delta * 1000 : delta),
//     // The `id` value will be unique to the current page load. When sending
//     // multiple values from the same page (e.g. for CLS), Google Analytics can
//     // compute a total by grouping on this ID (note: requires `eventLabel` to
//     // be a dimension in your report).
//     event_label: id,
//     // Use a non-interaction event to avoid affecting bounce rate.
//     non_interaction: true,
//   });
// }

// getCLS(sendToGoogleAnalytics);
// getFID(sendToGoogleAnalytics);
// getLCP(sendToGoogleAnalytics);
